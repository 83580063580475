img[alt="Google"] {
    display: none!important;
}

a[title="Open this area in Google Maps (opens a new window)"] {
    display: none!important;
}

div > div > a[title="Report problems with Street View imagery to Google"] {
    display: none!important;
}