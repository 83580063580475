.map {
    position: absolute;
    top: 0;
    left: 0;
}

.overlay {
    position: absolute;
    z-index: 99999;
    top: 2em;
    left: 0;
    width: 100%;
}

.text-box {
    width: 50vw;
    padding: 1em;
    max-width: 600px;
    color: #fff;
    margin: auto;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.85);
    border: 2px solid #000;
    border-radius: 5px;
}

.text-box > h1 {
    font-size: 1.25em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1em;
}

.text-box > input {
    padding: .5em;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    text-align: center;
}

.text-box > p {
    margin-top: .4em;
    margin-bottom: 0;
}

.text-box > img {
    position: relative;
    top: 6px;
    margin: 0 8px;
    height: 22px;
    width: 22px;
}

.overlay-status {
    display: block;
    margin-top: 1em;
    color: rgb(71, 227, 84);
}
